@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Noto+Kufi+Arabic:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    font-family: "Noto Kufi Arabic", cursive;
}

@layer base {
  body {
    @apply font-sans text-gray-800 bg-gray-50;
  }
}

@layer components {
  .btn {
    @apply px-6 py-3 rounded-lg font-medium transition-all duration-200;
  }
  
  .btn-primary {
    @apply bg-primary text-white hover:bg-primary-dark;
  }
  
  .btn-secondary {
    @apply bg-white text-primary border border-primary hover:bg-gray-50;
  }
  
  .container {
    @apply px-4 mx-auto max-w-7xl sm:px-6 lg:px-8;
  }
  
  .section {
    @apply py-12 md:py-20;
  }
}
